/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetIncidentsAviationRiskRatings200,
  GetIncidentsIdRiskAssessments200,
  GetIncidentsIdRiskAssessmentsParams,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get a risk assessment for the incident
 */
export const getIncidentsIdRiskAssessments = (
  id: string,
  params?: GetIncidentsIdRiskAssessmentsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsIdRiskAssessments200>> => {
  return axios.get(`/incidents/${id}/risk-assessments`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentsIdRiskAssessmentsQueryKey = (
  id: string,
  params?: GetIncidentsIdRiskAssessmentsParams,
) => {
  return [
    `/incidents/${id}/risk-assessments`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetIncidentsIdRiskAssessmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdRiskAssessmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetIncidentsIdRiskAssessmentsQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>
  > = ({ signal }) =>
    getIncidentsIdRiskAssessments(id, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetIncidentsIdRiskAssessmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>
>;
export type GetIncidentsIdRiskAssessmentsQueryError =
  AxiosError<JSONAPIErrorResponse>;

export function useGetIncidentsIdRiskAssessments<
  TData = Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params: undefined | GetIncidentsIdRiskAssessmentsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetIncidentsIdRiskAssessments<
  TData = Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdRiskAssessmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetIncidentsIdRiskAssessments<
  TData = Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdRiskAssessmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a risk assessment for the incident
 */

export function useGetIncidentsIdRiskAssessments<
  TData = Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdRiskAssessmentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdRiskAssessments>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetIncidentsIdRiskAssessmentsQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get worst case risk assessments for all incidents
 */
export const getIncidentsAviationRiskRatings = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsAviationRiskRatings200>> => {
  return axios.get(`/incidents/aviation-risk-ratings`, options);
};

export const getGetIncidentsAviationRiskRatingsQueryKey = () => {
  return [`/incidents/aviation-risk-ratings`] as const;
};

export const getGetIncidentsAviationRiskRatingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncidentsAviationRiskRatingsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>
  > = ({ signal }) =>
    getIncidentsAviationRiskRatings({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetIncidentsAviationRiskRatingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>
>;
export type GetIncidentsAviationRiskRatingsQueryError =
  AxiosError<JSONAPIErrorResponse>;

export function useGetIncidentsAviationRiskRatings<
  TData = Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetIncidentsAviationRiskRatings<
  TData = Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetIncidentsAviationRiskRatings<
  TData = Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get worst case risk assessments for all incidents
 */

export function useGetIncidentsAviationRiskRatings<
  TData = Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getIncidentsAviationRiskRatings>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetIncidentsAviationRiskRatingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
